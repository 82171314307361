
  /* Quill */

  .quill {
    position: fixed;
    top: 60px;
    left: 0;
    width: calc(100% + 0px);
    background-color: deeppink;
  }

  .ql-toolbar {
    /* display: inline; */
    /* height: 46px; */
    z-index: 100;
    position: fixed;
    top: ; /*54px;*/
    bottom: ; 
    left: -1px;
    width: calc(100% + 2px);
    /* margin-bottom: calc(20px + env(keyboard-inset-height)); */
    /* overflow: auto; */
    /* -webkit-overflow-scrolling: auto; */
    /*-- Mobile horizontal scroll --*/
    /* white-space: nowrap; */
    background-color: rgb(247, 247, 247);
  }

.ql-toolbar::-webkit-scrollbar {
  display: none; 
  /* for Chrome, Safari, and Opera */
}

.ql-snow .ql-tooltip {
  z-index: 100;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  position: absolute;
}

#ql-picker-options-0, #ql-picker-options-1, #ql-picker-options-2 {
  position: fixed;
  top: var(--vpH);
  left: -1px;
  width: calc(100% + 1px);
  z-index: 100;
  text-align: center;
  box-shadow: none;
}

.ql-snow .ql-picker-options .ql-picker-item {
  /* position: fixed;
  top: 50px; */
}

.ql-container {
  /* box-sizing: border-box; */
  z-index: 10;
  /* display: flex;
  flex-direction: column-reverse; */
  position: fixed;
  top: ; /*100px;*/
  bottom: ; /*46px*/
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% - 100px);
  overflow-y: auto;
  min-height: 20vh;
  background-color: rgb(255, 255, 255);
}

  .ql-editor {
    white-space: pre-wrap !important;
    padding: 12 16px;
    cursor: text;
    font-size: 1rem;
    min-height: 10rem;
    /* height: 100px; */
    position: absolute;
    z-index: 0;
    top: 0px;
    width: 100%;
    overflow: auto;
    tab-size: 5;
    -moz-tab-size: 5;
    -o-tab-size:  5;
    border: 0px solid rgb(189, 189, 189);
    border-top: 1px solid rgb(189, 189, 189);
    background-color: rgb(239, 233, 252);
  }

.ql-editor::-webkit-scrollbar {
  /* display: none;  */
  /* for Chrome, Safari, and Opera */
  /* width: 20px; */
  /* background: rgb(165, 13, 13); */
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 28px;
  padding: 3px 5px;
  width: 28px;
}

.ql-snow .ql-picker {
  color: #444;
  display: inline-block;
  float: left;
  font-size: 16px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;
}


.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
}

..ql-picker-options {
  position:absolute;
  top: 0; left: 0;
  z-index: 100;
  background-color: rgb(177, 166, 20);
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  /* position: fixed; */
  /* top: calc(100% - 200px); */
  /* bottom: -200px; */
  /* height: 200px; */
  /* right: 0; */
  /* width: 100%; */
  /* z-index: 100; */
}

#ql-picker-options-0 {
  /* position: fixed;
  top: calc(100% - 44px - 154px);
  text-align: center;
  left: -1px;
  width: calc(100% + 1px);
  z-index: 100;
  box-shadow: none; */
}

#ql-picker-options-1, #ql-picker-options-2 {
  /* position: fixed;
  top: calc(100% - 44px - 48px);
  text-align: center;
  left: -1px;
  width: calc(100% + 1px);
  z-index: 100;
  box-shadow: none; */
}

/*-- Lists indents --*/
.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 0rem;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 1.5rem;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 3rem;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 4.5rem;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 6rem;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 7.5rem;
}