/* NOTE EDITOR/PREVIEW (MAIN) STYLES */

.app-main {
    /* width: 100%; */
    /* height: 100%; */
    position: absolute;
    top:0; bottom:0; right:0; left:0;
    overflow: hidden;
    /* margin: 8px; */
  }
  
  .no-active-note {
    width: 70%;
    height: 100vh;
    line-height: 100vh;
    text-align: center;
    font-size: 2rem;
    color: #999;
  }
    
  
/* Editing */

.app-main-note-edit {
   position: fixed;
    top:0; bottom:0px; right:0; left:0;
    z-index: 100;
    overflow: hidden;
    /* margin: 8px; */
    /* background-color: rgb(247, 240, 150); */
  }
  
  .app-main-note-edit input {
    display: block;
    position: fixed;
    top:0; bottom:0px; right:0; left:0;
    z-index: 100;
    overflow: hidden;
    border: 0px solid #ddd;
    margin: 4px 4px 4px 8px;
    width: 100%;
    padding: 0px;
    resize: none;
    font-size: 1.5rem;
    font-family: inherit;
    height: 46px;
    width: calc(100% - 64px);
    font-size: 1.5em;
    font-weight: bold;
    margin-right:78px;
    /* background-color: rgb(247, 240, 150); */
  }
  
  
  /* Preview */
  .app-main-note-preview {
    border-top: 1px solid #ddd;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.02);
  }
  
  .preview-title {
    padding: 25px 25px 0 25px;
    margin: 0;
  }
  
  .markdown-preview {
    padding: 0 25px 25px 25px;
    font-size: 1rem;
    line-height: 2rem;
  }
