/* @import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"); */

* {
  box-sizing: border-box;
}

/* GLOBAL STYLES */

html, body, #root, .App {
  position:absolute;
  top:0; bottom:0; right:0; left:0;
  overflow: hidden;
  /* height:100%; */
  /* margin: 8px; */
  /* overscroll-behavior-y: none; */
  font-size: 14px;
}

html.is-locked,
html.is-locked body {
    /* want to fix the height to the window height */
    height: calc(var(--window-inner-height) - 1px);

    /* want to block all overflowing content */
    overflow: hidden;

    /* want to exclude padding from the height */
    box-sizing: border-box;
}

.App {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  overflow: hidden;
  font-size: 14px;
  /* background: url(https://www.toptal.com/designers/subtlepatterns/patterns/lightpaperfibers.png); */
}

button {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  color: #08c;
}
button:hover {
  color: #04c;
}

