/* html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;
  height: 100vh;
}

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
} */

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 20px;
  height: 18px;
  right: 20px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 64px;
  height: 64px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #000000;
  width: 6px;
  height: 28px;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(241, 241, 241);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #444444;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#react-burger-cross-btn {

}
