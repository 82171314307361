

.app-notestree {
    width: 100%;
    height: calc(100% - 100px);
    /* border-right: 1px solid #ddd; */
    white-space: nowrap;
    
  }
  
  .app-notestree-header {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    color: rgb(48, 48, 48);
  }
  .app-notestree-header h1 {
    margin: 0;
  }

  .app-notestree-notes {
    margin: 16px 0 0 16px;
  }

  ul, #myUL {
    list-style-type: none;
  }
  
  #myUL {
    margin: 0;
    padding: 0;
  }

  #notesTree {
    margin: 0;
    padding: 0;
  }
  
  .caret {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
    margin-left: -16px;
    float: left;
  }
  
  .caret::before {
    content: "\2303";
    font-weight: bold;
    color: rgb(165, 165, 165);
    display: inline-block;
    margin-right: 6px;
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari */
    transform: rotate(90deg);  
  }
  
  .caret-down::before {
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Safari */
    transform: rotate(180deg);  
  }
  
  .nested {
    display: none;
  }
  
  .active {
    display: block;
  }

  .treeItems:hover > .treeItems {
    background-color: rgb(233, 233, 233);
  }

  .treeItems.active {
    background: rgb(177, 177, 177);
    color: white;
  }

  .toMove {
    color: red;
    font-style: italic;
  }